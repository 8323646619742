import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Typography } from '@material-ui/core';
import PageSection from '../components/PageSection';
import Footer from '../components/Footer';
import TopBar from '../components/TopBar';
import { purples } from '../theme';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const BookPage = () => {
  const data = useStaticQuery(graphql`
    query {
      mainBackground: file(relativePath: { eq: "rowing-ant.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      serviceBackground: file(relativePath: { eq: "overhead-weights.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 5192) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      site {
        siteMetadata {
          contact {
            email
            phoneNumber
          }
        }
      }
    }
  `);

  const getPhoneNumber = () => data.site.siteMetadata.contact.phoneNumber;
  const getEmail = () => data.site.siteMetadata.contact.email;

  const iframeMessageHandler = (e) => {
    var clinikoBookings = document.getElementById('cliniko-58070814');
    if (typeof e.data !== 'string') return;
    if (e.data.search('cliniko-bookings-resize') > -1) {
      var height = Number(e.data.split(':')[1]);
      clinikoBookings.style.height = height + 'px';
    }
    e.data.search('cliniko-bookings-page') > -1 &&
      clinikoBookings.scrollIntoView();
  };

  useEffect(() => {
    window.addEventListener('message', iframeMessageHandler);
    return () => {
      window.removeEventListener('message', iframeMessageHandler);
    };
  }, []);

  return (
    <>
      <TopBar />
      <div style={{ paddingTop: '90px', backgroundColor: purples[3] }} />

      <PageSection
        id="booking"
        title="BOOKING DETAILS"
        bgColor={purples[3]}
        labelColor="black"
      >
        <Typography variant="body1" style={{ color: 'black' }} gutterBottom>
          We'd love you to complete the online booking below.
          <br />
          Alternatively you can email us at{' '}
          <a
            href={`mailto:${getEmail()}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {getEmail()}
          </a>{' '}
          or call us on{' '}
          <a
            href={`tel:${getPhoneNumber()}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {getPhoneNumber()}
          </a>
          . Or you can send us an enquiry via the online{' '}
          <AnchorLink to="/#contact">contact form</AnchorLink>.
        </Typography>
        <iframe
          title="cliniko-58070814"
          id="cliniko-58070814"
          src="https://prep-physio-and-performance.au1.cliniko.com/bookings?embedded=true"
          frameBorder="0"
          scrolling="auto"
          width="100%"
          height="1000"
          style={{ pointerEvents: 'auto', minWidth: '100%', minHeight: '100%' }}
        ></iframe>
      </PageSection>
      <Footer />
    </>
  );
};

export default BookPage;
